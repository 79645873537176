import React, { useEffect, useMemo, useState } from 'react';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { Button, Hidden, TableRow } from '@mui/material';
import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '@crema/components/AppInfoView';
import AppSearchBar from '@crema/components/AppSearchBar';
import OrderTable from '../PendingOrders/OrderTable';
import { useAppDispatch, useAppSelector } from '../../../toolkit/hooks';
import { getRecentOrders } from '../../../toolkit/actions';
import jwtAxios from "../../../@crema/services/auth/jwt-auth";
import { useNavigate } from "react-router-dom";
import DateFormat from "../../../@crema/helpers/DateFormat";
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { StyledTableCell } from 'modules/extraPages/Pricing/PackageTable/Table/index.styled';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeleteCase = () => {
    const { messages } = useIntl();
    const dispatch = useAppDispatch();
    const [cases, setCases] = useState([])
    const [page, setPage] = useState(0);
    const [loadingTable, setLoadingTable] = useState(false)
    const [search, setSearch] = useState('');
    const [totalOrders, setTotalOrders] = useState(0)

    const rowsPerPage = 10;

    // const recentOrders = useMemo(() => {
    //     if (orders.length > 0) {
    //         console.log("wut?")
    //         return orders
    //             .map(cats => ({ id: cats._id, orderID: cats.orderID, creditsUsed: cats.creditsUsed, companyName: cats.companyName, credentialID: cats.credentialID, "product": cats.orderDetails.map((orderrr) => { return `${orderrr.name} - ${orderrr.sku} - ${orderrr.qty}\n` }), "date": dayjs(cats.createdOn).format('MM/DD/YYYY HH:mm:ss'), "status": cats.status, "price": cats.ammount, "notes": cats.notes }));
    //     } else {
    //         return []
    //     }
    // }, [orders, search])

      const onPageChange = (event, value) => {
        setPage(value);
      };

      const onSearchCustomer = (e) => {
        setSearch(e.target.value);
        setPage(0);
      };

    const updateTable = () => {
        jwtAxios.get( 'api/case' ).then((response) => {
            const sortedCases = response.data.data.caseList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setCases(sortedCases);
            setTotalOrders(response.data.data.caseList.length);
            setLoadingTable(false)
            // console.log("loading the cases list", response.data)
        });
    }
    useEffect(() => {
        updateTable()
    }, [page]);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this case?')) {
          jwtAxios.delete(`api/case/${id}`)
            .then(() => {
              setCases(cases.filter(caseItem => caseItem._id !== id));
              toast.success('Case deleted successfully.')
            })
            .catch((error) => {
              console.error('Error deleting case:', error);
              toast.error('Failed to delete the case.');
            });
        }
    };

    //   const onSearchOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchQuery(e.target.value);
    //     setPage(0);
    //   };

    const locate = useNavigate()
    const handleViewOrder = (id) => {
        console.log("the id", id)
        locate("/orders/viewOrder", { state: { id } })
    }

    const filteredCases = cases.filter(caseItem =>{
        // caseItem.name.toLowerCase().includes(search.toLowerCase())
        const searchTerm = search.toLowerCase();
        return (
            caseItem.name.toLowerCase().includes(searchTerm) ||
            caseItem.licensePlate.toLowerCase().includes(searchTerm) ||
            caseItem.caseId.toLowerCase().includes(searchTerm)
        )
    });
    
    const paginatedCases = filteredCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <AppsContainer
                title={'Delete Case'}
                fullView
            >
                <AppsHeader>
                    <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        width={1}
                        justifyContent='space-between'
                    >
                        <AppSearchBar
                            iconPosition='right'
                            overlap={false}
                            onChange={onSearchCustomer}
                            placeholder={"Search Here"}
                        />
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            {/*<Button variant='contained' color='primary'>*/}
                            {/*  Add Order*/}
                            {/*</Button>*/}

                            <Hidden smDown>
                                <AppsPagination
                                    rowsPerPage={rowsPerPage}
                                    count={totalOrders}
                                    page={page}
                                    onPageChange={onPageChange}
                                />
                            </Hidden>
                        </Box>
                    </Box>
                </AppsHeader>

                <AppsContent
                    sx={{
                        paddingTop: 2.5,
                        paddingBottom: 2.5,
                    }}
                >
                    <Table stickyHeader className='table'>
                        <TableHead>
                            <TableCell>Case Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Number</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Vehicle Name</TableCell>
                            <TableCell>Color</TableCell>
                            <TableCell>License Plate</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableHead>
                        <TableBody>

                        {paginatedCases.map((data) => (
                            <TableRow key={data.id} className='item-hover'>
                                <StyledTableCell align='left'>{data.caseId}</StyledTableCell>
                                <StyledTableCell align='left'>
                                    <Box
                                        // onClick={() => navigate(`/invoice/pdf/${data.id}`)}
                                        component='span'
                                        color='primary.main'
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {data.name}
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align='left' sx={{ width: 400 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {data.number}
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align='left' sx={{ width: 400 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {data.email}
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell align='left'>
                                    {dayjs(data.createdAt).format('DD/MM/YYYY')}
                                </StyledTableCell>
                                <StyledTableCell align='left'>{data.vehicleName}</StyledTableCell>
                                <StyledTableCell align='left'>{data.color}</StyledTableCell>
                                <StyledTableCell align='left'>{data.licensePlate}</StyledTableCell>
                                <StyledTableCell align='left'>{data.address}</StyledTableCell>
                                <TableCell align='center'>
                                    <Button
                                        sx={{ display: 'block', minWidth: 100 }}
                                        color='primary'
                                        variant='contained'
                                        type='button'
                                        onClick={() => handleDelete(data._id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </AppsContent>

                <Hidden smUp>
                    <AppsPagination
                        rowsPerPage={rowsPerPage}
                        count={totalOrders}
                        page={page}
                        onPageChange={onPageChange}
                    />
                </Hidden>
            </AppsContainer>
            <ToastContainer /> {/* To show pop up message */}
            <AppInfoView />
        </>
    );
};

export default DeleteCase;
